* {
    margin: 0;
    padding: 0
}

html {
    height: 100%
}

body {
    background-color: #000000
}

.card0 {
    margin: 40px 12px 15px 12px;
    border: 0
}

.card1 {
    margin: 0;
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 0px;
    background: #fdf5f5;
    height: 100%
}

.bill-head {
color: #2f313a !important;
font-weight: 400 !important;
margin-bottom: 0;
margin-top: 0;
font-size: 14px !important;
line-height: 40px !important;
}

.line {
    border-right: 1px grey solid
}

.bill-date {
    color: #BDBDBD
}
.bill-side{
    min-height: 500px;
}
.red-bg {
    /* margin-top: 25px; */
    /* margin-top: 38%; */
    margin-left: 0px;
    margin-right: 0px;
    background-color: #ED1C24;
    padding-left: 20px !important;
    /* padding: 25px 10px 25px 15px */
    padding: 10px 10px 10px 10px;
    position: absolute;
    bottom: 0;
}

#total {
    margin-top: 0px;
    padding-left: 7px;
        color: #fff !important;
        font-weight: bold !important;
}

#total-label {
    margin-bottom: 0px;
    color: #ffffff;
    padding-left: 7px
}

#heading1 {
    color: #2f313a !important;
    font-size: 18px !important;
    padding-left: 0px;
}

#heading2 {
    /* font-size: 27px; */
    font-size: 18px !important;
    color: #2f313a !important;
}

.placeicon {
    font-family: fontawesome !important
}

.card2 {
    padding: 25px;
    margin: 0;
    height: 100%
}

.form-card .pay {
      font-weight: bold;
      line-height: 18px;
      font-size: 12px;
      color:#2f313a;
}

.form-card input,
.form-card textarea {
    /* padding: 10px 8px 10px 8px;
    border: none;
    border: 1px solid lightgrey;
    border-radius: 4px; */
    /* margin-bottom: 25px; */
    /* margin-bottom: 10px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat !important;
    color: #2C3E50;
    font-size: 14px; */
    border-radius: 3px;
    height: 41px;
    line-height: 41px;
    color: #9097a1;
    font-size: 12px;
    background-color: #fdfdfd;
    padding: 0 15px;
    width: 100%;
    border: 1px solid #eceef4;
    font-family: 'Open Sans',
    sans-serif !important;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.make-pay-btn{
     font-family: 'Open Sans',
     sans-serif !important;
     font-size: 14px;
}
.form-card input:focus,
.form-card textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none;
    font-weight: bold;
    border: 1px solid gray;
    outline-width: 0
}

.btn-info {
    color: #ffffff !important
}

.radio-group {
    position: relative;
    margin-bottom: 25px
}

.radio {
    /* display: inline-block;
    width: 204;
    height: 64;
    border-radius: 0;
    background: lightblue;
    box-sizing: border-box;
    border: 2px solid lightgrey;
    cursor: pointer;
    margin: 8px 25px 8px 0px */
    display: inline-block;
    width: 100px;
    height: 55px;
    border-radius: 0;
    background: transparent;
    box-sizing: border-box;
    border: 1px solid #d3d3d3;
    cursor: pointer;
    margin: 8px 8px 8px 0;
    text-align: center;
}
.radio img{
    width: 90px;
    height: 50px;
    overflow: hidden;
    object-fit: fill;
}
.radio:hover {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2)
}

.radio.selected {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4)
}

.fit-image {
    width: 100%;
    object-fit: cover
}